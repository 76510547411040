@use "./common/css/base" as base;

.app-container {
  display: flex;
  flex: auto;
  width: 100vw;
  height: 100vh;
  background-color: #4e4d4d;
  /* background: url("../public/images/main_background.svg") no-repeat; */
  background-size: cover;
  justify-content: center;
}

@media screen and (max-width: 1365px) {
  .section-holder {
    margin: 4px 10px 0px 10px;
  }
}

@media screen and (min-width: 1366px) {
  .section-holder {
    margin: 5px 10.67px 0px 10.67px;
  }
}

.header-holder {
  display: flex;
  flex: auto;
  max-height: var(--custom-page-header-height);
  padding-bottom: var(--custom-page-header-padding);
}

.content-holder {
  display: flex;
  flex: auto;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.file-uploader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999; /* Ensure it's above AG-Grid */
  pointer-events: none; /* Disable mouse events for the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}