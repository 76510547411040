@use '../../../common/css/base' as base;

.iframe-div {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 62% !important;
}

.value-label {
  font-family: base.$font;
  font-style: normal;
  font-size: var(--custom-text-font-size);
  line-height: var(--custom-line-height);
  font-weight: var(--custom-edit-header-font-weight);
  display: flex;
  align-items: center;
  color: #404040;
  cursor: pointer;
}

.value-pair {
  display: flex;
  flex-direction: column;
  padding: 0.125rem;
  // gap: 0.625rem;
}

.value-action {
  flex: 1;
  display: flex;
  gap: 0.25em;
  justify-content: end;
}

.value-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto; /* gap: 1.25em; */
  padding-right: 0.625rem;
  gap: 0.2rem;
}

.abstractor {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #f2f2f2;

  box-shadow: 0px 4px 25px #404040;
  border-radius: 0px;
  // margin: 1%;
}

.heading-font {
  font-family: base.$font;
  font-weight: var(--custom-edit-header-font-weight);
  font-size: var(--custom-edit-header-tag-font-size);
  line-height: 1.5;
  font-style: normal;
  display: flex;
  align-items: center;
}

.abstractor-heading {
  @extend .heading-font;
  color: #ffffff;
}

.ab-frame-header {
  background: var(--AP-black, #2e2e2e);
}
.abstractor-file-name {
  @extend .heading-font;
  color: #ffb000;
  border-radius: 7.5px 0px 0px 0px;
  padding: 8px 14px 8px 14px;
}

.ab-file-container {
  width: 100%;
}
.abstractor-file-details {
  color: #ffb000;
  display: flex;
  flex: 1;
  font-size: var(--custom-text-font-size);
  justify-content: space-between;
  padding: 8px 14px 8px 14px;
  color: var(--AP-yellow, #ffb000);
  line-height: var(--custom-line-height);
}

.abstractor-details-key {
  color: var(--AP-yellow, #ffb000);
  text-align: center;
  font-weight: 275;
}

.abstractor-details-value {
  font-weight: 700;
}

.abstractor-header {
  display: flex;
  /* flex: 1; */
  background: #2e2e2e;
  box-shadow: 0px 9.174762725830078px 57.342262268066406px 0px #404040;
  border-radius: 0px;
  padding: 1.5%;
}

.absractor-values {
  /* AP light grey */
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f2f2f2;
  box-shadow: 0px 4px 25px #404040;
  border-radius: 0px;
  max-width: 45% !important;
  padding: 0.625rem;
}

.action-container {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin: 10px;
}

.abstractor-divider {
  display: flex;
  height: 5%;
}

.abstractor-html {
  display: flex;
  flex: 1;
}

.absractor-divide {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

iframe {
  display: flex;
  // width: 62% !important;
  border: 0px;
  // padding: 0.313rem;
  /*width: 600px;
  height: 550px; */
  -moz-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.html-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.button-text {
  font-size: var(--custom-close-font-size);
}
.edit-close-button {
  display: flex;
  height: 35px;
  padding: 5.872px 17.615px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  border-radius: 4.5px;
  background: var(--AP-grey, #404040);
}

.highlighted {
  background-color: red;
}
