$popup-background-color: #4e4d4d !default;
$popup-border-radius: 0.322rem !default;
$popup-border: 1px solid #404040 !default;
$popup-box-shadow: 0px 4px 25px #404040 !default;

@mixin popup-defaults {
  // border: $popup-border;
  box-shadow: $popup-box-shadow;
  border-radius: $popup-border;
  background: $popup-background-color;
}

.abstracted-values-content {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  background: #f2f2f2;
  border: 1px solid #404040;
  box-shadow: 0px 4px 25px #404040;
  border-radius: 5px;
  margin: 1%;
}

.settings-values-content {
  @include popup-defaults();
  width: 900px;
  height: 600px;
}

.upload-values-content {
  @include popup-defaults();

  display: flex;
  border-radius: var(--custom-upload-dialog-border-radius);
  min-width: calc(var(--custom-upload-dialog-width));
  min-height: calc(var(--custom-upload-dialog-height));
  max-height: var(--custom-upload-dialog-height);
  max-width: var(--custom-upload-dialog-width);
  flex: 1 0;
}
