.tsm-container {
  width: 379px;
  height: 242.516px;
  display: flex;
  flex-direction: column;
  position: absolute;
  color: white;
  gap: 0.5rem;
  font-size: 1rem;
  padding: 1.5rem;
  background: var(--AP-black-gray, #404040);
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25);
  box-sizing: content-box;
  .custom-line {
    border: none;
    height: 1px; /* Adjust the height as needed */
    background-color: #fff; /* Change to desired color */
    width: 100%; /* Adjust width if needed */
  }
  .tsm-btn {
    width: 3.75rem !important;
    font-size: 0.75rem;
    height: 1.5rem;
    padding: 5.872px 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 3.523px;
    font-family: Poppins;
    font-weight: 600;
    color: #404040;
    background: var(--AP-white, #fff);
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
  .tsm-btn-disable {
    background-color: #585858;
    pointer-events: none;
  }
  .tsm-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .tsm-header-left {
      font-family: Poppins;
      font-size: 11.743px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      flex-wrap: nowrap;
      align-items: center;
      .react-toggle {
        height: 12.21px;
      }
      .react-toggle--checked .react-toggle-track {
        border-radius: 28px;
        border: 1px solid var(--AP-medium-yellow, #FAD47F) !important;
        background: var(--Dark-orange, #E99210) !important;
      }
      .react-toggle-track {
        height: 12.21px !important;
        width: 22px !important;
      }
      .react-toggle-thumb {
        top: 2px !important;
      }
      .react-toggle--checked {
        left: 12px !important;
      }
      .react-toggle-thumb {
        height: 8.75px !important;
        width: 7.75px !important;
      }
    }
    .tsm-header-right {
      text-align: center;
      font-family: Poppins;
      font-size: 10.275px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
  .tsm-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
      font-size: 0.625rem;
      font-weight: 500;
      font-family: Poppins;
      width: calc(100% - 1rem);
      height: 21px;

      outline: none;
    }
    input::placeholder {
      font-style: italic;
    }
    span {
      color: var(--AP-white, #fff);
      text-align: center;
      font-family: Poppins;
      font-size: 10.275px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .tsm-notify-container,
    .tsm-past-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: baseline;
    }
  }
  .tsm-filter-shared {
    color: #ffb000;
  }
  .tsm-remove-access {
    color: #585858;
  }
  .tsm-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    gap: 0.5rem;
    .tsm-action-left {
      width: 100%;
    }
    .tsm-action-right {
      display: flex;
      flex-direction: row;
      gap: 0.75rem;
    }
    .tsm-btn-orange {
      background-color: #ffb000;
    }
  }
  .tsm-past {
    display: flex;
    /* flex: 1 1; */
    flex-direction: column;
    justify-content: start;
    align-items: baseline;
    width: 100%;
    overflow: auto;
    height: 4rem !important;
    span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.44px;
    }
  }
}

.react-toggle--checked .react-toggle-thumb {
  left: 12px !important;
}