.tf-state-controls {
  padding: 0.5rem;
  column-count: 3;
  column-width: auto;
  overflow-y: auto;
  .tf-state-content {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 0.15rem;
    font-size: 0.6875rem;
    align-items: center;
    height: inherit;
  }
}
.tf-checkbox {
  /* Hide the default checkbox */
  .custom-checkbox {
    display: none;
  }

  /* Style the label to resemble a checkbox */
  label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 0.7rem;
  }

  /* Create the checkbox design */
  label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    border: 2px solid #ccc;
    background-color: transparent;
  }

  /* Style the checkbox when it's checked */
  .custom-checkbox:checked + label:before {
    background-color: #ffb000 !important; /* Change this to the desired color */
    border-color: #ffb000;
  }
  .checkbox-disabled {
    cursor: auto;
  }
  .checkbox-disabled + label:before{
    pointer-events: none;
    cursor: auto;
    background-color: #bbb !important; /* Change this to the desired color */
    border-color: #bbb;
  }
}
