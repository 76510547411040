@use '../../../../common/css/base' as base;
.value-edited {
  background-color: #b6dea8 !important;
}

.value {
  font-family: base.$font;
  font-size: var(--custom-text-font-size);
  font-style: normal;
  font-weight: 400;
  padding: 0.3125em;
  line-height: var(--custom-line-height);
  color: base.$ap-black;
  height: auto;
  display: flex;
  align-items: center;
  width: 100%;
  resize: none;
  overflow: hidden;
  outline: none;
  border: none;
  margin-right: 3px;
}

.value-selected {
  background: base.$ap-medium-orange;
}

.value-not-selected {
  background: base.$ap-header-gray;
}

.value-holder {
  display: flex;
  flex-direction: row;
}

.value-reset {
  cursor: pointer;
  margin: 4px;
  font-style: italic;
  display: flex;
  align-items: center;
  color: var(--AP-grey, #404040);
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.value-reset:hover {
  transform: scale(1.1);
  text-decoration: underline;
}

.value-textbox-container {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.value-save-tick {
  margin-left: 3px;
  img {
    width: 1rem;
    height: 1rem;
  }
}

.value-tick-disabled {
  pointer-events: none;
  cursor: default;
}
