@use '../../../../common/css/base' as base;

.gs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.gs-container-header-text {
  margin-bottom: 1rem;
}
.gs-controls-container {
  display: flex;
  flex-direction: column;
  height: 355px;
}
.gs-button-container {
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
}
.gs-reset-holder {
  margin-top: 0.5rem;
}
.gs-group {
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem;
  .gs-label {
    width: 200px;
    color: white;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
  .gs-slider, .gs-input {
    width: 55%;
    // padding-top: 2rem;
  }

  .gs-input {
    display: flex;
  }
  .gs-slider-small {
    width: 15%;
    .rc-slider-step {
      padding: 0 5px !important;
      :first-child {
        left: 1.5% !important;
      }
    }
    .rc-slider-step span:nth-child(3) {
      left: 98% !important;
    }

    .rc-slider-step > span:nth-child(n + 4) {
      height: 11px !important;
    }
  }
  .gs-controls {
    width: 3.5rem;
    display: flex;
    margin-left: 1rem;
    img {
      cursor: pointer;
      margin-left: 0.25rem;
      width: 1.4rem;
    }
    img:hover {
      transform: scale(1.1);
    }
  }
  .gs-input-small {
    width: 32px;
    height: 25px;
    padding: 4px !important;
    text-align: center;
    // font-size: 0.9rem;
    font-family: Poppins;
  }
  .gs-input {
    margin-left: 1.75rem;
  }
  .gs-slider {
    margin-left: 2rem;
    padding-top: 1rem;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.gs-controls-legend {
  color: var(--AP-white, #FFF);
  font-size: 0.75rem;
  padding-top: 0.55rem;
  padding-left: 0.75rem;
}

.rc-slider-mark {
  top: -25px !important;
}

.rc-slider-mark-text {
  font-size: var(--custom-gs-rc-slider-mark-text);
  font-weight: 400;
  line-height: 12px;
  width: 40px;
  bottom: -10px;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.rc-slider-rail {
  height: 6px !important;
}

.rc-slider-step {
  padding: 0 5px !important;
  :first-child {
    left: 0.5% !important;
  }
}

.rc-slider-step span:nth-child(7) {
  left: 99.5% !important;
}

.rc-slider-step > span:nth-child(n + 8) {
  height: 11px !important;
}

.rc-slider-handle {
  width: 6px !important;
}

.gs-reset-btn {
  width: auto;
  border: 0.25px solid var(--AP-black-gray, #404040);
  background: var(--AP-medium-gray, #b8b8b8);
}

.gs-control-input .gs-controls {
  // margin-top: auto;
}
