@use "../../common/css/base" as base;

.drag-text {
  display: flex;
  width: 228.639px;
  height: 12.33px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--AP-white, #fff);
  font-family: Poppins;
  font-size: 17.615px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.661px;
}

.user-container {
  // position: relative;
}

@media screen {
  :root {
    --custom-CB-fontSize: 11.743px;
    --custom-upload-btn-fontSize: 10.966px;
    --custom-user-details-fontSize: 12.532px;
  }
  .header-button-v4-text {
    color: var(--AP-light-gray, #f2f2f2);
    text-align: center;
    font-family: Poppins;
    font-size: var(--custom-CB-fontSize);
    font-style: normal;
    font-weight: 600;
    line-height: 1.12rem;
  }
  .header-button-v4 {
    display: flex;
    width: 140px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 8.807px;
    flex-shrink: 0;
    background: var(--AP-black, #2e2e2e);
    filter: drop-shadow(4px 8px 10px #656565);
  }

  .drag-continer-text-size {
    font-size: var(--custom-user-details-fontSize);
    font-weight: 600;
    line-height: 17.545px;
  }
  .d-actions-container {
    gap: 21.34px;
  }
  .user-container {
    gap: 6.25px;
  }
  .user-details-text-size {
    font-size: var(--custom-user-details-fontSize);
  }
}

.choose-file-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: base.$ap-white;
  flex-shrink: 0;
}

.choose-button-text {
  color: base.$ap-black;
  text-align: center;
  font-family: base.$font;
  font-style: normal;
}

.drag-continer-text {
  font-family: base.$font;
  font-style: normal;
  color: base.$ap-white;
  gap: 8.19px;
}

.image-container {
  display: flex;
  // width: calc(100% / 3);
  align-items: center;
  justify-content: left;
  gap: 12px;
}

.actions-container {
  display: flex;
  // width: calc(100% / 3);
  align-items: center;
  justify-content: end;
}

.action-buttons {
  font-family: base.$font;
  font-style: normal;
  font-weight: 600;
  color: base.$ap-white;
}

.user-details {
  font-family: base.$font;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: right;
  color: #ffffff;
}

input[type="file"]::file-selector-button {
  background: #404040;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.uploader-area {
  display: flex;
  width: calc(100% / 3);
}
.user-profile-image {
    &:hover {
      transform: scale(1.15);
    }
}
.user-profile-menu {
  position: absolute;
  right: 15px;
  top: 45px;
  background-color: white;
  padding: 7px 10px;
  z-index: 100000;
  &:hover {
    background-color: #FAD47F;
    cursor: pointer;
  }
}