html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Poppins" !important;
}

.disable-pointer-events {
  pointer-events: none;
}

.visibilty-not-hiddent {
  visibility: visible;
}

.visible-hidden {
  visibility: hidden;
}

.bold-text {
  font-weight: bold !important;
}

a,
button,
img {
  cursor: pointer;
  border-style: none;
}

.cursor-default {
  cursor: default;
}

input {
  padding: 5px !important;
}

.button-orange {
  background: #ffb000;
  border-color: #ffb000;
}

.button-grey {
  background: #404040;
  border-color: #404040;
}

::-webkit-scrollbar {
  margin-left: 10px;
  width: 14px;
  height: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dcdcdc;
  border-radius: 6px;
  transform: rotate(90deg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 6px;
  transform: rotate(90deg);
}

.hide {
  display: none !important;
}

:root {
  --toastify-font-family: "Poppins" !important;
  --toastify-color-progress-light: #fad47f;
}
